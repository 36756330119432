<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								<h4>Tambah Satuan</h4>
							</CCol>
							
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<div class="form-group">
								<label for=""><b>Kategori<span class="text-danger">*</span></b></label>
								<v-select :options="list_kategori" label="label" v-model="st_kategori"></v-select>
							</div>
							<div class="form-group">
								<label for=""><b>Nama Status<span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" placeholder="Nama Status" v-model="st_nama">
							</div>
							<div class="form-group">
								<label for=""><b>Role Verifikator<span class="text-danger">*</span></b></label>
								<v-select :options="list_role" label="name" v-model="role_selected"></v-select>
							</div>
							<div class="form-group">
								<label for=""><b>Urutan<span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" placeholder="Urutan Status" v-model="st_urutan">
							</div>
							<div class="form-group">
								<label for=""><b>Kategori Status<span class="text-danger">*</span></b></label>
								<v-select :options="list_kategori_status" label="label" v-model="st_kategori_status"></v-select>
							</div>
							

						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
							</div>
						</CCardFooter>
					</CForm>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	export default {
		name: "AddStatusPermohonan",
		data() {
			return {
				st_kategori: '',
				st_nama: '',
				roles_id: '',
				st_urutan: '',
				role_selected: [],
				st_kategori_status: '',
				list_role: [],
				list_kategori: [
                    {
                        value: "Hortikultura",
                        label: "Hortikultura",
                    },
                    {
                        value: "Sumber Daya Genetik",
                        label: "Sumber Daya Genetik",
                    },
                    {
                        value: "Tanaman Pangan",
                        label: "Tanaman Pangan",
                    },
                    {
                        value: "Perkebunan",
                        label: "Perkebunan",
                    },
                ],
				list_kategori_status: [

					
					{
                        value: "draft",
                        label: "draft",
                    },
					
					{
                        value: "ajukan",
                        label: "Pengajuan Permohonan",
                    },
					
					{
                        value: "proses",
                        label: "Proses Verifikasi",
                    },
					
					{
                        value: "dibatalkan",
                        label: "Batal",
                    },
					
					{
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
					
					{
                        value: "selesai",
                        label: "Selesai",
                    },

				]
			};
		},
		methods: {
			
			storeProcess: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				var FormData = require('form-data');
				var data = new FormData();
				data.append('st_kategori', this.st_kategori?.value);
				data.append('st_nama', this.st_nama);
				data.append('st_urutan', this.st_urutan);
				data.append('roles_id', this.role_selected?.id);
				data.append('st_kategori_status', this.st_kategori_status?.value);
				
				var config = {
				  method: 'post',
				  url: this.apiLink+'api/crud/status_permohonan',
				  headers: { 
					'Authorization': 'Bearer '+this.access_token, 
				  },
				  data : data
				};
				axios(config)
				.then(function (response) {
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Anda akan diarahkan ke halaman master status permohonan segera",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								self.$router.push({name: 'AllStatusPermohonan'});
							}
						});
					}
				});
			},
		},
		created(){
			axios.get(this.apiLink + "api/master/role", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_role = response.data;
				var res_role_data = res_role.data;
				if (res_role.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_role.data.message
					});
				}
				else{
					this.list_role = res_role_data.master;
				}
			});
		}
	};
</script>